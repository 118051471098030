import { updatePrimaryPalette } from "@primevue/themes";
import { watch } from "vue";
export default defineNuxtPlugin((nuxtApp) => {
  const themeStore = siteDataService();

  const updateCssVariable = (colors: {}) => {
    if (process.client) {
      if (colors.primary) {
        const accent = colors.primary;
        document.documentElement.style.setProperty(
          "--qc-primary-color",
          accent
        );
        document.documentElement.style.setProperty(
          "--p-button-primary-background",
          extractColorFromGroup(
            colors?.buttons?.button_primary.color_group,
            colors
          )
        );
        document.documentElement.style.setProperty(
          "--p-button-secondary-background",
          extractColorFromGroup(
            colors?.buttons?.button_secondary.color_group,
            colors
          )
        );
        document.documentElement.style.setProperty(
          "--p-button-primary-border",
          extractColorFromGroup(
            colors?.buttons?.button_primary_border.color_group,
            colors
          )
        );
        document.documentElement.style.setProperty(
          "--p-button-secondary-border",
          extractColorFromGroup(
            colors?.buttons?.button_secondary_border.color_group,
            colors
          )
        );
        document.documentElement.style.setProperty(
          "--qc-borders-primary-color",
          extractColorFromGroup(
            colors?.borders?.border_primary.color_group,
            colors
          )
        );
        document.documentElement.style.setProperty(
          "--p-tabs-active-bar-background",
          extractColorFromGroup(
            colors?.borders?.border_primary.color_group,
            colors
          )
        );

        updatePrimaryPalette({
          600: accent,
        });
      }
    }
  };

  const extractColorFromGroup = (
    value: {
      is_unique: boolean;
      preset: string;
      custom_preset_number: number;
      color: string;
    },
    pallete: {}
  ) => {
    if (value.is_unique) return value.color;
    if (value.preset === "primary") return pallete.primary;
    return (
      pallete.custom_colors[value.custom_preset_number - 1]?.color || "#000"
    );
  };

  const getColors = () => {
    return themeStore.siteData?.theme_options?.theme?.theme_colors || {};
  };

  // Set initial value, using the safe accessor
  updateCssVariable(getColors());

  // Watch for changes, using a computed property to handle potential undefined values
  watch(
    () => getColors(),
    (newValue) => {
      updateCssVariable(newValue);
    }
  );
});
