import { defineNuxtPlugin } from '#app'
import { gsap } from 'gsap'
import Lenis from '@studio-freight/lenis';
import ScrollTrigger from 'gsap/ScrollTrigger'

export default defineNuxtPlugin((nuxtApp) => {
  // Register the ScrollTrigger plugin
  gsap.registerPlugin(ScrollTrigger)
  // init lenis
  const lenis = new Lenis({
    duration: 1.2,
    easing: function(t){return (t === 1 ? 1 : 1 - Math.pow(2, -10 * t))},
    orientation: "vertical",
    gestureOrientation: "vertical",
    smoothWheel: true,
    syncTouch: false,
    touchMultiplier: 2,
  })

  function raf(time: any) {
    lenis.raf(time)
    requestAnimationFrame(raf)
  }

  requestAnimationFrame(raf)

  // GSAP ScrollTrigger integration

  lenis.on('scroll', ScrollTrigger.update)

  gsap.ticker.add((time)=>{
    lenis.raf(time * 1000)
  })

  gsap.ticker.lagSmoothing(0)
  // Provide gsap and ScrollTrigger globally
  nuxtApp.provide('gsap', gsap)
  nuxtApp.provide('ScrollTrigger', ScrollTrigger)

  // Example: You can add some global ScrollTrigger settings here if needed
  ScrollTrigger.defaults({
    markers: false, // Set to true for development
    // Other default settings
  })
})