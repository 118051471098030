import { defineStore } from "pinia";

export const popupService = defineStore({
  id: "popupService",
  state: (): {} => {
    return {
      _globalPopups: [],
      _optionalPopupsData: {},
      _registeredOptionalPopups: [],
      _registeredPopups: [],
      _activePopup: null,
      _activePopupData: {},
      _newsletterEmail: undefined,
    };
  },
  getters: {
    popups: (state: {}) => [...state._registeredPopups, ...state._globalPopups],
    activePopup: (state: {}) => state._activePopup,
    activePopupData: (state: {}) => state._activePopupData,
  },
  actions: {
    openPopup(key: string, data: {} = {}) {
      const popup = this.popups.find(
        (popup: { key: string }) => popup.key === key
      );
      if (!!popup) {
        this.closePopup();
        this._activePopup = key;
        this._activePopupData = data;
      }
    },
    registerPopup(key: string, type: string, data: {}) {
      if (
        !this._registeredPopups.find(
          (popup: { key: string }) => popup.key === key
        )
      ) {
        this._registeredPopups.push({
          key,
          type,
          data,
        });
      }
    },
    closePopup() {
      this._activePopup = null;
      this._activePopupData = {};
    },
    registerOptionalPopup(type: string, data: {} = {}) {
      if (
        !this._registeredPopups.find(
          (popup: { key: string }) => popup.key === type
        )
      ) {
        this._registeredPopups.push({
          key: type,
          type,
          data,
        });

        this._registeredOptionalPopups.push(type);

        this._optionalPopupsData[type] = {};
      } else {
        const popup = this._registeredPopups.find(
          (popup: { key: string }) => popup.key === type
        );
        popup.data.data = Object.apply(data.data, popup.data.data);
      }
    },
    clearPopups() {
      this.closePopup();
      this._registeredPopups = [];
    },
    registerGlobalPopup(key: string, type: string, data: {}) {
      if (
        !this._globalPopups.find((popup: { key: string }) => popup.key === key)
      ) {
        this._globalPopups.push({
          key,
          type,
          data,
        });
      }
    },
    setNewsletterEmail(email: string | undefined) {
      this._newsletterEmail = email;
    },
  },
});
