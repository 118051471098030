<template>
  <template v-if="isModalLink">
    <!-- button for modal popup -->
    <button :class="buttonClasses" @click="checkClickForModal" :aria-label="$t('commons.open_overlay')">
      <span class="p-button-label">{{ props.data?.link?.title }}</span>
      <slot />
    </button>
  </template>
  <template v-else>
    <Button
      as="a"
      :href="dynamicUrl"
      :class="buttonClasses"
      :label="props.data?.link?.title" >
      <slot/>
    </Button>
  </template>
</template>

<script lang="ts" setup>
  import Button from 'primevue/button';

  const layoutServiceStore: any = siteDataService();
  const getSiteData = computed(() => layoutServiceStore.siteData);

  const props = defineProps<{
    data: Record<string, any>;
    dynamic?: boolean;
    animation?: string;
    class?: string;
  }>()

  const isModalLink = computed(() => props.data?.link?.url?.startsWith('#modal:'));
  const dynamicUrl = computed(() => replaceWpUrl(props.data?.link?.url));

  const buttonOptions = computed(() => {
    let options = null;
    if (props?.data?.preset === "INTERNAL")
      options = getSiteData?.value?.theme_options.button_preset?.cta_internal;
    if (props?.data?.preset === "EXTERNAL")
      options = getSiteData?.value?.theme_options?.button_preset?.cta_external;
    return options || props?.data?.options;
  })

  const buttonClasses = computed(() => {
    const classes: string[] = ['comp-button'];
    if (props.dynamic) {
      classes.push('dynamic-width');
    }
    if (props.animation) {
      classes.push('type-' + props.animation);
    }
    if (buttonOptions.value?.enable_border) {
      classes.push('border-enabled');
    }

    if(props.class){
      classes.push('type-' + props.class);
    } else {
      classes.push('type-' + buttonOptions.value?.type);
    }


    return classes;
  })
  
const checkClickForModal = (event: Event) => {
  event.preventDefault();
  handleModalPopup(dynamicUrl.value);
};

</script>
<style>
.comp-button.type-PRIMARY_FILLED:hover,
.comp-button.type-PRIMARY_FILLED,
.p-button:not(:disabled):active,
.p-button:not(:disabled):hover {
    background: var(--p-button-primary-background);
}
</style>