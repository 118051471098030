// Function to handle modal popup logic
export function handleModalPopup(destination: string) {
  const popupManager = popupService();
  if (!destination) return;

  if (destination.startsWith('#modal:')) {
    const parts = destination.split(':').slice(1);
    if (!!parts.length) {
      const options = {};
      if (!!parts[1]) {
        options.key = parts[1];
      }
      popupManager.openPopup(parts[0], options);
    }
  }
}

// Function to handle regular link logic
export async function handleRegularLink(destination: string, router?: any) {
  if (!destination) return;

  if (destination.startsWith('http')) {
    const localUrl = stripLocalBaseUrl(destination);
    if (localUrl !== destination) {
      // Navigate to internal route
      await router.push(localUrl.length > 0 ? localUrl : { name: 'index' });
    } else {
      // Open external link in a new tab
      window.open(destination, '_blank');
    }
  }
}