import { defineStore } from "pinia";

export const globalState = defineStore({
  id: "globalState",

  state: () => {
    return {
      _currentLanguage: "en",
      _currentPath: null as string | null,
      _siteLanguages: [
        {
          code: "en",
          name: "EN",
          default: true
        },
        {
          code: "it",
          name: "IT",
          default: false
        },
        {
          code: "fr",
          name: "FR",
          default: false
        },
        {
          code: "de",
          name: "DE",
          default: false
        }
      ],
      _currentPageTranslation: null,
      _currentHrefLang: null,
      _currentScreenOrientation: "l",
      _currentScreenWidth: 0,
      _currentScreenHeight: 0,
      _isMobile: false,
      _enableRefreshOnResize: false,
      _enableResponseCaching: false,
      _headerHeight: 0,
      _latestResize: 0,
      _loading: false
    };
  },

  getters: {
    currentLanguage: (state: { _currentLanguage: {} }) => state._currentLanguage,
    currentPageTranslation: (state: { _currentPageTranslation: any }) => state._currentPageTranslation,
    currentHrefLang: (state: { _currentHrefLang: any }) => state._currentHrefLang,
    currentScreenOrientation: (state: { _currentScreenOrientation: string }) => state._currentScreenOrientation,
    currentScreenWidth: (state: { _currentScreenWidth: number }) => state._currentScreenWidth,
    currentScreenHeight: (state: { _currentScreenHeight: number }) => state._currentScreenHeight,
    enableRefreshOnResize: (state: { _enableRefreshOnResize: boolean }) => state._enableRefreshOnResize,
    enableResponseCaching: (state: { _enableResponseCaching: boolean }) => state._enableResponseCaching,
    isLoading: (state: { _loading: boolean }) => state._loading,
    siteLanguages: (state: { _siteLanguages: any }) => state._siteLanguages,
    headerHeight: (state: { _headerHeight: number }) => state._headerHeight,
    currentPath: (state: { _currentPath: string | null }) => state._currentPath,
    isMobile: (state: { _isMobile: boolean }) => state._isMobile,
    latestResize: (state: { _latestResize: number }) => state._latestResize,
  },

  actions: {
    //@ qui controllare se serve x change language
    async updateLayout() {
      const layoutServiceStore = siteDataService();
      await layoutServiceStore.getHeader(this._currentLanguage);
      await layoutServiceStore.getFooter(this._currentLanguage);
    },

    setLoading(newLoading: boolean) {
      this._loading = newLoading;
    },

    setCurrentPath(newPath: string) {
      this._currentPath = newPath;
    },

    setHeaderHeight(newHeightHeight: number) {
      this._headerHeight = newHeightHeight;
    },

    setCurrentScreenOrientation(newScreenOrientation: string) {
      this._currentScreenOrientation = newScreenOrientation;
    },

    setCurrentScreenWidth(newScreenWidth: number) {
      this._latestResize = Date.now();
      this._currentScreenWidth = newScreenWidth;
    },

    setIsMobile(isMobile: boolean) {
      this._isMobile = isMobile;
    },

    setCurrentScreenHeight(newScreenHeight: number) {
      this._currentScreenHeight = newScreenHeight;
    },

    //@ da qui in poi controllare se serve x change language
    setCurrentLanguage(newCurrentLang: string) {
      if (!existLanguage(newCurrentLang)) {
        this._currentLanguage = "en";
      } else {
        this._currentLanguage = newCurrentLang;
      }
    },

    updateCurrentHrefLang(newCurrentHrefLang: any) {
      this._currentHrefLang = newCurrentHrefLang;
    },
    

    updateCurrentPageTranslations(localesAll: any, translations: any, locale: string, customCanonical:any | string = ""): void {
      const globalTranslationsUrl: any = {};
      const arrayHrefLang: any = [];

      if (localesAll)
        if (typeof translations !== "undefined") {
          localesAll.forEach(function (key: any, value: string) {

            let permalink = "";

            const valueLang = translations[key.code];
            if (valueLang && valueLang?.permalink) {
              permalink = valueLang.permalink;
              
              if (permalink !== "" && permalink.slice(-1) === "/") permalink = permalink.slice(0, -1);
              globalTranslationsUrl[key.code] = {
                permalink: replaceWpUrl(permalink),
                category: null,
                lang: key.code,
                name: key.name,
                missing: valueLang?.missing,
              };
            }

            // for hreflang
            if (globalTranslationsUrl[key.code]?.permalink){
              arrayHrefLang.push({
                rel: "alternate",
                hreflang: key.code,
                href:  globalTranslationsUrl[key.code]?.permalink ,
              });
            }

          });
        } else {

          /* for 404 page */

          const switchLocalePath = useSwitchLocalePath();

          localesAll.forEach(function (key: any, value: string) {
            
              const path = switchLocalePath(key.code);
              //console.log("path");
              //console.log(path);
              globalTranslationsUrl[key.code] = {
                permalink: replaceWpUrl(path),
                category: "",
                lang: key.code,
                name: key.name,
                missing: "",
              };

              // for hreflang
              if (globalTranslationsUrl[key?.code]?.permalink){
                arrayHrefLang.push({
                  rel: "alternate",
                  hreflang: key.code,
                  href:  globalTranslationsUrl[key.code].permalink ,
                });
              }
         
          });
        }
      arrayHrefLang.push({
        rel: "canonical",
        href: (customCanonical && customCanonical!=="") ? customCanonical : globalTranslationsUrl[locale]?.permalink,
      });

      this.updateCurrentHrefLang(arrayHrefLang);
      this._currentPageTranslation = globalTranslationsUrl;
    },

    setEnableRefreshOnResize(newEnableRefreshOnResize: boolean) {
      this._enableRefreshOnResize = newEnableRefreshOnResize;
    },
  },
});
