import { useRuntimeConfig } from '../node_modules/nuxt/dist/app';

export default defineNuxtPlugin((nuxtApp: any) => {
  const config = useRuntimeConfig();

  // onBeforeLanguageSwitch called right before setting a new locale
  nuxtApp.$i18n.onBeforeLanguageSwitch = (oldLocale: any, newLocale: any, isInitialSetup: any, nuxtApp: any) => {
    // set change language and update header + footer !!
  };

  // onLanguageSwitched called right after a new locale has been set
  nuxtApp.$i18n.onLanguageSwitched = (oldLocale: any, newLocale: any) => {
  };
});
/**/