import { defineNuxtPlugin } from '#app'
import LocomotiveScroll from 'locomotive-scroll'
import { gsap } from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

export default defineNuxtPlugin((nuxtApp) => {
  let locoScroll: LocomotiveScroll | null = null

  gsap.registerPlugin(ScrollTrigger)

  const initLocoScroll = async () => {
    locoScroll = new LocomotiveScroll({
      el: document.querySelector('[data-scroll-container]') as HTMLElement,
      smooth: true,
      getDirection: true,
      // Add other Locomotive Scroll options here
    })

    locoScroll.on('scroll', ScrollTrigger.update)

    ScrollTrigger.scrollerProxy('[data-scroll-container]', {
      scrollTop(value) {
        if (arguments.length) {
          locoScroll!.scrollTo(value, { duration: 0, disableLerp: true });
          ScrollTrigger.update();
          return;
        }
        return locoScroll!.scroll.instance.scroll.y;
      },
      getBoundingClientRect() {
        return {
          top: 0,
          left: 0,
          width: window.innerWidth,
          height: window.innerHeight,
        }
      },
      pinType: 'transform'
    })

    ScrollTrigger.defaults({ scroller: '[data-scroll-container]' })

    const lsUpdate = () => {
      if (locoScroll) {
        locoScroll.update()
      }
    }

    ScrollTrigger.addEventListener('refresh', lsUpdate)
    ScrollTrigger.refresh()
  }

  nuxtApp.vueApp.directive('locomotive', {
    mounted(el) {
      el.setAttribute('data-scroll-container', '')
      initLocoScroll()
    },
    getSSRProps() {
      return {}
    },
  })

  return {
    provide: {
      LocomotiveScroll,
      lsUpdate: () => {
        if (locoScroll) {
          locoScroll.update()
          ScrollTrigger.refresh()
          window.dispatchEvent(new Event('resize'));
        }
      },
      getLocoScroll: () => locoScroll,
    },
  }
})