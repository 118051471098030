import { defineStore } from "pinia";

export const siteDataService = defineStore({
  id: "siteDataService",
  state: () => {
    return {
      _siteData: {},
      _bodyClass: "",
    };
  },
  getters: {
    siteData: (state: { _siteData: {} }) => state._siteData,
    bodyClass: (state: { _bodyClass: string }) => state._bodyClass,
  },
  actions: {
    setClass(bodyClass: string) {
      this._bodyClass = bodyClass;
    },
    async getSiteData(lang: string) {
      if (typeof lang === "undefined") lang = "en";
      try {
        const getDataSite = await useAsyncData("/api/siteData", () => {
          const result = $fetch("/api/siteData", {
            method: "post",
            body: { lang: lang },
          });

          return result;
        });

        await this.updateSiteData(getDataSite.data);
                
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      }
    },
    async updateSiteData(siteData: any) {
      this._siteData = siteData;
    },
  },
});
