//import { useRuntimeConfig } from "@/node_modules/nuxt/dist/app";
enum languages {
  en,
  it,
  fr,
  de
}

export function existLanguage(lang: string): boolean {
  return Object.values(languages).includes(lang) ? true : false;
}

export function replaceWpUrl(url: string) {
    if (typeof url === "undefined" || url === null || url === "") return "#";
    const config = useRuntimeConfig();
    const wpurl = config.public.apiWpUrl;
    let feurl = config.public.apiWebsite;
    if (process.client) if (window.location.origin.includes("localhost")) feurl = window.location.origin;
  
    if (url.slice(-1) === "/") url = url.slice(0, -1);
    if (url.indexOf("/wp-content/uploads/") >= 0) return replaceCdnUrl(url);
    else {
      if (typeof wpurl !== undefined && wpurl !== "" && typeof feurl !== undefined && feurl !== "") {
        return url.replace(wpurl, feurl);
      } else {
        return url;
      }
    }
}

export function replaceCdnUrl(assetUrl: string): string {
  const config = useRuntimeConfig();
  const wpUrl = config.public.apiWpUrl;
  const cdnUrl = config.public.cdnUrl;

  //@todo - scommentare x quando far salire la cdn
  /*let replacedUrl = "";
  if (typeof assetUrl === "string" && assetUrl !== "") {
    if (assetUrl.slice(-1) === "/") assetUrl = assetUrl.slice(0, -1);
    replacedUrl = assetUrl.replace("http://", "https://").replace(wpUrl, cdnUrl);
  }

  return replacedUrl;*/
  return assetUrl;
}

export function checkIfWpUrl(url: string) {
  if (typeof url === "undefined" || url === null || url === "") return "#";
  const config = useRuntimeConfig();
  const wpurl = config.public.apiWpUrl;

  if (typeof wpurl !== undefined && wpurl !== "" && url.includes(wpurl)) {
    return replaceWpUrl(url);
  } else {
    return url;
  }
}

export function excludeDomainFromUrl(url: string) {
  if (typeof url === "undefined" || url === null || url === "") return url;

  const config = useRuntimeConfig();
  const wpurl = config.public.apiWpUrl;

  if (typeof wpurl !== undefined && wpurl !== "") {
    return url.replace(wpurl, ""); 
    //@todo - scommentare x quando sito è avanti - necessario x gestire pagine figle lanciate senza il parent nell'url
    //return url.replace("http://", "https://").replace(wpurl, "");    
  } else {
    return url;
  }
}

export function stripLocalBaseUrl(url: string) {
  if (!url) return "";
  url =  url.replace(window.location.origin, "");
  return !!url.length ? url : "/";
}

export function linkType(url: string) {
  const config = useRuntimeConfig();
  const wpUrl = config.public.apiWpUrl;
  if (url.includes(wpUrl)) return "internal";
  else if(url.includes('#modal')) return "technical";
  else "external";
}